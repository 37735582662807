import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import NarrowContent from "../components/visualContainers/narrowContent"
import PageTitle from "../components/contentComponents/pageTitle"
import {H2, H3, P} from "../components/contentComponents/text"
import styled from "styled-components"
import WideImage from "../components/contentComponents/wideImage"
import MaterialContainer from "../components/visualContainers/materialContainer"
import mainImage from "../images/merchant1.jpg"
import heroImage from "../images/index2.jpg"
import devicesImage from "../images/devices.png"
import plusImage from "../images/plus.svg"
import equalsImage from "../images/equals.svg"
import flagsImage from "../images/flags.svg"
import cardsImage from "../images/card_logos.png"
import Wall from "../components/visualComponents/wall"
import PartnerLogos from "../components/contentComponents/partnerLogos"
import GlowStick from "../components/visualComponents/glowstick"
import Button, { Buttons } from "../components/contentComponents/button"
import Disc from "../components/visualComponents/disc"
import Cross from "../components/visualComponents/cross"
import { useEffect, useState } from "react"

const MerchantPage = (props) => {
  const t = props.pageContext.pageData
  const {site, locale, pageName, fullPath} = props.pageContext

  const [merchantOnboardingURL, setMerchantOnboardingURL] = useState("")

  useEffect(() => {
    setMerchantOnboardingURL("https://merchant." + window.location.host + "/onboarding")
  }, []);

  return (
    <Layout site={site} language={locale} pagePath={props.path} pageName={pageName}>
      <Seo lang={locale} pagePath={fullPath} site={site} meta={t.seo} />
      <NarrowContent>
        <MobileHeroTitle title={t.homepageHero.title} />
      </NarrowContent>
      <HeroImage image={heroImage}>
        <BubbleAnimator>
          <HeroBubbleContainer className="center-vertical" material="purpleGradient" circle={true} width={300} zIndex={6}>
            <DiscImage src={devicesImage} alt="" />
          </HeroBubbleContainer>
        </BubbleAnimator>
        <HeroTitle>{t.homepageHero.title}</HeroTitle>
        <Wall id={"wall1"} hideMobile={true} height={40} position="top: 0; right: -80px;"/>
        <Wall id={"wall2"} hideMobile={true} height={40} position="top: 80px; right: -80px;"/>
      </HeroImage>
      <NarrowContent>
        <GlassTextPanel mb={90} material="transparent" zIndex={7} className="motion" dataYFrom={40} dataYTo={-40} style={{transform: 'translate3D(0, var(--y40), 0)'}}>
          <H3 strong={true} white={true}>
            {t.homepageHero.bodyText1}
          </H3>
          <Flags className="center-vertical">
            <FlagsImage src={flagsImage} alt="" />
          </Flags>
          <H3 strong={true} white={true}>
            {t.homepageHero.bodyText2}
          </H3>
        </GlassTextPanel>

        <Buttons className="center" mt={48} mb={48}>
          <Button title={t.homepageHero.button1Title} linkTo={site.pages.invoice.defaultPath}/>
          <Button title={t.homepageHero.button2Title} linkTo={site.pages.slice.defaultPath}/>
        </Buttons>
      </NarrowContent>
      <WideImage image={mainImage}>
        <BubbleAnimator>
          <BubbleContainer className="center-vertical" material="purpleGradient" circle={true} width={300} zIndex={6}>
            <P center={true} white={true}>{t.hero.bubbleText}</P>
          </BubbleContainer>
        </BubbleAnimator>
        <Wall id={"wall3"} hideMobile={true} height={40} position="top: 0; left: -40px;"/>
        <Wall id={"wall4"} hideMobile={true} height={40} position="top: 0; left: -70px;"/>
        <Wall id={"wall5"} hideMobile={true} height={40} position="bottom: 5px; left: -40px;"/>
        <Wall id={"wall6"} hideMobile={true} height={120} position="bottom: 80px; right: -70px;"/>
        <Wall id={"wall17"} hideDesktop={true} height={48} position="left: 10px; top: -290px;"/>
        <Wall id={"wall18"} hideDesktop={true} height={48} position="left: 30px; top: -290px;"/>
        <Disc material="lightBlueOpaque" hideMobile={true} zIndex={6} width={64} position="left: -50px; top: 100px;"/>
        <Disc material="lightBlueOpaque" hideDesktop={true} zIndex={6} width={55} position="right: 10px; top: -310px;"/>
      </WideImage>
      <NarrowContent>
        <GlassTextPanel mb={90} material="transparent" zIndex={7} className="motion" dataYFrom={40} dataYTo={-40}>
          <H3 strong={true} white={true}>
            {t.hero.bodytext}
          </H3>
        </GlassTextPanel>
        <Buttons className="center" mt={80} mb={80} >
          <Button external={true} title={t.hero.buttonTitle} linkTo={merchantOnboardingURL}/>
        </Buttons>
        <div style={{position: "relative"}}>
          <Wall id={"wall7"} hideMobile={true} height={40} position="top: 0; right: -70px;"/>
          <Wall id={"wall8"} hideMobile={true} height={40} position="top: 70px; right: -70px;"/>
          <Cross hideMobile={true} width={100} position="left: -120px; top: 190px;"/>
          <MaterialContainer zIndex={1} material="walled">
            <H3 strong={true} mb={32}>{t.walledBoxes.title}</H3>
            <Gardens>
              <MaterialContainer columns={2} zIndex={1} material="walled" padding={40}>
                <P strong={true}>{t.walledBoxes.box1Title}</P>
                <P addMargins={true} mt={16}>{t.walledBoxes.box1Body}</P>
                <div className="center motion" data-y-from={10} data-y-to={-10}>
                  <img src={cardsImage} alt="" />
                </div>
              </MaterialContainer>
              <MaterialContainer columns={2} zIndex={1} material="walled" padding={40}>
                <P strong={true}>{t.walledBoxes.box2Title}</P>
                <P addMargins={true} mt={16}>{t.walledBoxes.box2Body}</P>
                <div className="center motion" data-y-from={10} data-y-to={-10}>
                  <img src={cardsImage} alt="" />
                </div>
              </MaterialContainer>
            </Gardens>
          </MaterialContainer>
        </div>

        <Buttons className="center" mt={80} mb={80} >
          <Button external={true} title={t.walledBoxes.buttonTitle} linkTo={merchantOnboardingURL}/>
        </Buttons>

        <div style={{position: "relative"}}>
          <Wall id={"wall10"} hideMobile={true} height={64} position="top: 0; left: -70px;"/>
          <Wall id={"wall11"} hideMobile={true} height={64} position="top: 0; left: -40px;"/>
          <BlackContainer mt={80} mb={80} material="almostBlack" className="motion" dataYFrom={-40} dataYTo={40}>
            <div className="center">
              <GlowStick width={160} />
            </div>
            <H2 color="#6A44D9" white={true} mt={40}>
              {t.integration.partnersTitle}
            </H2>
            <P white={true} addMargins={true}>{t.integration.partnersBody}</P>
            <PartnerLogos partners={[]}/>
            <div className="center">
              <GlowStick width={160} />
            </div>
          </BlackContainer>
        </div>
        <H2ResponsiveMargin mb={80} strong={true}>
          <Wall id={"wall12"} hideMobile={true} height={40} position="top: 0; right: -80px;"/>
          <Wall id={"wall13"} hideMobile={true} height={40} position="top: 60px; right: -80px;"/>
          <div>
            {t.integration.stepsTitle}
          </div>
        </H2ResponsiveMargin>

        <div className="motion" data-y-from={20} data-y-to={-20}>
          <H3 color="#6A44D9" strong={true} mb={24}>{t.integration.stepsSubtitle}</H3>
        </div>

        <HowContainer>
          <Wall id={"wall14"} hideMobile={true} height={30} position="top: 0; left: -65px;"/>
          <Wall id={"wall15"} hideMobile={true} height={30} position="top: 0; left: -45px;"/>
          <Wall id={"wall16"} hideMobile={true} height={40} position="top: 0; right: -30px;"/>
          <Flex>
            <MathBox padding={24} material="almostBlack">
              <P white={true} strong={true}>{t.integration.step1Title}</P>
              <P mt={true} small={true} white={true} addMargins={true}>{t.integration.step1Body}</P>
              <AutoBottom>
                <GlowStick width={80} />
              </AutoBottom>
            </MathBox>
            <div className="center">
              <MathIcon src={plusImage} alt="Plus" />
            </div>
            <MathBox padding={24} material="brightBlue">
              <P strong={true} mb={11}>{t.integration.step2Title}</P>
              <BoxText mt={true} small={true}>{t.integration.step2Item1}</BoxText>
              <BoxText small={true}>{t.integration.step2Item2}</BoxText>
              <BoxText small={true}>{t.integration.step2Item3}</BoxText>
              {/*<BoxText small={true}>{t.integration.step2Item4}</BoxText>*/}
            </MathBox>
            <div className="center">
              <MathIcon src={equalsImage} alt="Equals" />
            </div>
            <MathBox padding={24} material="almostBlack">
              <P white={true} strong={true}>{t.integration.step3Title}</P>
              <P mt={true} small={true} white={true} addMargins={true}>{t.integration.step3Body}</P>
              <BoxText mt={true} white={true} small={true}>{t.integration.step3Item1}</BoxText>
              <BoxText white={true} small={true}>{t.integration.step3Item2}</BoxText>
              <AutoBottom>
                <GlowStick width={80} />
              </AutoBottom>
            </MathBox>
          </Flex>
        </HowContainer>

        <Buttons className="center" mb={40} mt={80}>
          <Button external={true} title={t.integration.buttonTitle} linkTo={merchantOnboardingURL}/>
        </Buttons>
      </NarrowContent>

    </Layout>
  );
}

export default MerchantPage


const HeroImage = styled(WideImage)`
  @media only screen and (min-width: 768px) {
    margin-top: 40px;
  }
`

const Flags = styled.div`
  width: 100%;
  margin-top: 32px;
  margin-bottom: 16px;
`

const FlagsImage = styled.img`
  
`


const Gardens = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  
  @media only screen and (max-width: 767px) {
    flex-direction: column;
    
    & > div {
      width: 100%;
      margin-bottom: 16px;
    }
  }
`

const HowContainer = styled.div`
  position: relative;
  width: 100%;
  
  @media only screen and (max-width: 767px) {
    margin-top: -100px;
    margin-bottom: -100px;
  }
`

const Flex = styled.div`
  position:relative;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  margin-bottom: 80px;
  z-index: 3;
  
  @media only screen and (max-width: 767px) {
    max-width: 100vw;
    overflow-x: scroll;
    padding: 100px 32px;
    margin-left: -32px;
    margin-right: -32px;
    margin-bottom: 0;
  }
`

const MathBox = styled(MaterialContainer)`
  position: relative;
  flex: 0 0 auto;
  width: calc((100% - 120px) / 3);
  min-width: unset;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 24px;
  
  @media only screen and (max-width: 767px) {
    min-width: 220px;
  }
`

const MathIcon = styled.img`
  width: 32px;
  height: 32px;
  margin: 12px;
`

const BoxText = styled(P)`
  display: block;
  width: 100%;
  padding: 8px;
  border: 1px solid ${props => props.white ? "#ffffff" : "#0C1524"};
  border-radius: 3px;
  margin: 5px 0;
`

const AutoBottom = styled.div`
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding-top: 24px;
`

const BlackContainer = styled(MaterialContainer)`
  padding: 24px 48px;
  
  @media only screen and (max-width: 767px) {
    margin-top: 40px;
    margin-bottom: 40px;
  }
`

const BubbleAnimator = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 6;
`

const BubbleContainer = styled(MaterialContainer)`
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translate(-50%, -100%);
  
  @media only screen and (min-width: 768px) {
    position: absolute;
    top: -140px;
    left: 24px;
    transform: unset;
    
    & > div {
      padding: 24px;
    }
  }
  
  @media only screen and (min-width: 1024px) {
    position: absolute;
    bottom: 110px;
    top: unset;
    left: 30px;
    transform: unset;
    border-radius: 50%;
    width: 300px;
    
    & > div {
      padding: 48px;
    }
  }
`

const HeroBubbleContainer = styled(MaterialContainer)`
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translate(-50%, -100%);
  width: 300px;
  height: 300px;
  
  @media only screen and (min-width: 768px) {
    position: absolute;
    top: unset;
    left: unset;
    bottom: 60px;
    right: -20px;
    transform: scale(0.8);
    
    & > div {
      padding: 24px;
    }
  }
  
  @media only screen and (min-width: 1024px) {
    position: absolute;
    bottom: 120px;
    right: -65px;
    transform: unset;
    border-radius: 50%;
    
    & > div {
      padding: 48px;
    }
  }
`

const GlassTextPanel = styled(MaterialContainer)`
  margin-top: -80px;
  margin-bottom: 90px;
  
  @media only screen and (max-width: 767px) {
    margin-top: -40px;
    margin-bottom: 40px;
    
    & > h3 {
      font-family: Roboto, sans-serif;
      font-weight: 500;
      font-size: 18px;
      line-height: 140%;
    }
  }
  
  @media only screen and (min-width: 1024px) {
    margin-top: -140px;
  }
`

const H2ResponsiveMargin = styled(H2)`
  @media only screen and (max-width: 767px) {
    margin-bottom: 40px;
  }
`

const DiscImage = styled.img`
  transform: scale(0.5);
`

const HeroTitle = styled.h1`
  position: absolute;
  top: 0;
  left: 0;
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 120%;
  color: #FFFFFF;
  z-index: 5;
  margin: 24px;
  display: inline-block;
  flex: 0 0 auto;
  max-width: calc(100% - 48px);
  
  @media screen and (max-width: 767px) {
    display: none;
  }
  
  @media screen and (min-width: 1024px){
    font-size: 48px;
    margin: 32px;
    max-width: calc(100% - 64px);
  }
`

const MobileHeroTitle = styled(PageTitle)`
  display: block;
  margin-bottom: 30px;
  
  h1 {
    width: 100%;
  }
  
  @media screen and (min-width: 768px) {
    display: none;
  }
`
