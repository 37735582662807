import * as React from "react"
import styled from "styled-components"
import {P} from "./text"

const PartnerLogos = ({ partners, className }) => (
  <Container className={className}>
    {partners.map((partner, index) => (
      <Partner key={index}>
        <Logo src={partner.logo} alt={partner.name} />
        <P>{partner.name}</P>
      </Partner>
    ))}
  </Container>
)

export default PartnerLogos


const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  margin-bottom: 40px;
`

const Partner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`

const Logo = styled.img`
  
`
